import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import ReCAPTCHA from 'react-google-recaptcha'
import { isEmpty } from 'validator'
import Button from './Button'
import SelectShirtSize from './SelectShirtSize'
import { SHIRT_SIZES, PRODUCT_SYMBOLS } from '../utils'

// Note if you change these - update submit-form.js - i know, dank, but hey we inna rush
const bot = 'beep-boop'
const name = 'name'
const line1 = 'line1'
const line2 = 'line2'
const city = 'city'
const state = 'state'
const zip = 'zip'
const country = 'country'
const email = 'email'
const address = 'address'
const timestamp = 'timestamp'
const numberBurned = 'number-burned'
const signature = 'signature'

const selectShirtSizeDefaultValue = SHIRT_SIZES['Select size']

const recaptchaEnabled = false

export default function RedeemForm({
  setHasConfirmedAddress,
  setUserAddress,
  numberBurned: actualNumberBurned,
  chosenProductSymbol
}) {
  // map from variables to display text for each field

  // Note if you change this - update submit-form.js - i know, dank, but hey we inna rush
  const nameMap = {
    [name]: 'Name',
    [line1]: 'Street Address',
    [line2]: 'Unit',
    [city]: 'City',
    [state]: 'State',
    [zip]: 'ZIP/Post code',
    [country]: 'Country',
    [email]: 'Email',
    [address]: 'Ethereum Address',
    [timestamp]: 'Time',
    [numberBurned]: 'Number Redeemed'
  }

  function makeShirts(count) {
    let names = []
    var i = 0
    for (i = 0; i < count; i++) {
      const name = 'shirt' + i
      names.push(name)
    }
    return names
  }

  const shirts = makeShirts(actualNumberBurned)

  // the order for fields that will be submitted
  const nameOrder =
    chosenProductSymbol === PRODUCT_SYMBOLS.FAME
      ? shirts.concat([name, line1, line2, city, state, zip, country, email])
      : [name, line1, line2, city, state, zip, country, email]

  // default for each form field
  const defaultState = {
    [bot]: '',
    [name]: '',
    [line1]: '',
    [line2]: '',
    [city]: '',
    [state]: '',
    [zip]: '',
    [country]: '',
    [email]: ''
  }

  const { library, account } = useWeb3React()
  const [recaptcha, setRecaptcha] = useState()

  const [formState, setFormState] = useState(defaultState)

  function handleChange(event) {
    const { name, value } = event.target
    setFormState(state => ({ ...state, [name]: value }))
  }

  // keep acount in sync
  useEffect(() => {
    handleChange({ target: { name: [address], value: account } })
  }, [account])

  function shirtsValid() {
    let shirtsValid = false
    shirts.forEach(shirt => {
      shirtsValid = formState[shirt] && formState[shirt] !== selectShirtSizeDefaultValue
    })

    return shirtsValid
  }

  const canSign =
    !isEmpty(formState[name]) &&
    !isEmpty(formState[line1]) &&
    !isEmpty(formState[city]) &&
    !isEmpty(formState[state]) &&
    !isEmpty(formState[zip]) &&
    !isEmpty(formState[country]) &&
    !isEmpty(formState[email]) &&
    (chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? shirtsValid() : true)

  function onRecaptcha(value) {
    if (value) {
      setRecaptcha(value)
    }
  }

  function makeShirtInputs(count) {
    let inputs = []
    var i = 0
    for (i = 0; i < count; i++) {
      const name = 'shirt' + i

      inputs.push(
        <>
          <Header>Longsleeve #{i + 1}</Header>
          <SelectShirtSize required value={formState[name]} name={name} handleChange={handleChange} />
        </>
      )
    }
    return inputs
  }

  return (
    <>
      <FormFrame autocomplete="off">
        <input hidden type="text" name="beep-boop" value={formState[bot]} onChange={handleChange} />

        {chosenProductSymbol === PRODUCT_SYMBOLS.FAME && makeShirtInputs(actualNumberBurned)}

        <Header>Address</Header>
        <input
          required
          type="text"
          name={name}
          value={formState[name]}
          onChange={handleChange}
          placeholder={nameMap[name]}
          autoComplete="name"
        />

        <input
          required
          type="text"
          name={line1}
          value={formState[line1]}
          onChange={handleChange}
          placeholder={nameMap[line1]}
          autoComplete="off"
        />
        <Compressed>
          <input
            type="text"
            name={line2}
            value={formState[line2]}
            onChange={handleChange}
            placeholder={nameMap[line2]}
            autoComplete="off"
          />
        </Compressed>
        <input
          required
          type="text"
          name={city}
          value={formState[city]}
          onChange={handleChange}
          placeholder={nameMap[city]}
          autoComplete="address-level2"
        />

        <Compressed>
          <input
            style={{ marginRight: '8px' }}
            required
            type="text"
            name={state}
            value={formState[state]}
            onChange={handleChange}
            placeholder={nameMap[state]}
            autoComplete="address-level1"
          />
          <input
            required
            type="text"
            name={zip}
            value={formState[zip]}
            onChange={handleChange}
            placeholder={nameMap[zip]}
            autoComplete="postal-code"
          />
        </Compressed>

        <input
          required
          type="text"
          name={country}
          value={formState[country]}
          onChange={handleChange}
          placeholder={nameMap[country]}
          autoComplete="country-name"
        />

        <input
          required
          type="email"
          name={email}
          value={formState[email]}
          onChange={handleChange}
          placeholder={nameMap[email]}
          autoComplete="email"
        />

        {recaptchaEnabled && <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_RECAPTCHA_KEY} onChange={onRecaptcha} />}
        <ButtonFrame
          type="redeem"
          disabled={!canSign || (recaptchaEnabled && !!!recaptcha)}
          onClick={event => {
            let shirts = []
            nameOrder.forEach(o => {
              if (nameMap[o] === undefined) {
                shirts.push(formState[o])
              }
            })

            const signer = library.getSigner()
            const timestampToSign = Math.round(Date.now() / 1000)

            const header = `PLEASE VERIFY YOUR ADDRESS${
              chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? ' AND SHIRT SIZES' : ''
            }.\nYour data will never be shared publicly.`
            const formDataMessage = nameOrder
              .map(o => {
                if (nameMap[o] === undefined) {
                  return `Shirt: ${formState[o]}`
                }
                return `${nameMap[o]}: ${formState[o]}`
              })
              .join('\n')
            const autoMessage = `${nameMap[address]}: ${account}\n${nameMap[timestamp]}: ${timestampToSign}\n${
              nameMap[numberBurned]
            }: ${actualNumberBurned}`

            signer.signMessage(`${header}\n\n${formDataMessage}\n${autoMessage}`).then(returnedSignature => {
              fetch('/.netlify/functions/submit-form', {
                headers: {
                  'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                  'form-name': 'redeem',
                  ...{
                    ...formState,
                    shirts: shirts,
                    [address]: account,
                    [timestamp]: timestampToSign,
                    [numberBurned]: actualNumberBurned,
                    [signature]: returnedSignature,
                    chosenProduct: chosenProductSymbol,
                    ...(recaptchaEnabled ? { 'g-recaptcha-response': recaptcha } : {})
                  }
                })
              })
                .then(() => {
                  setHasConfirmedAddress(true)
                })
                .catch(console.error)
            })

            event.preventDefault()
          }}
          text={canSign ? 'Next' : 'Complete the form to continue'}
        />
        <br />
      </FormFrame>
    </>
  )
}

const FormFrame = styled.form`
  background: #242424;
  width: 100%;
  color: #000;
  font-weight: 600;
  padding: 16px;
  /* margin-bottom: 0px; */
  font-size: 16px;
  display: flex;
  flex-direction: column;
  input {
    font-family: 'Tenor Sans', sans-serif;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    text-indent: 20px;
    height: 70px;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #fffafa;
    }
    :-ms-input-placeholder {
      color: #fffafa;
    }
    color: white;
  }
  input:required {
    box-shadow: inset 0 0 0 1px rgba(254, 109, 222, 0.5);
  }
  input:valid {
    border: nne;
    box-shadow: none;
  }
`

const Header = styled.h1`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  margin-top: 30px;
  margin-bottom: 30px;
`

const Compressed = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const ButtonFrame = styled(Button)`
  margin-top: 20px;
  height: 70px;
`
