import React from 'react'
import styled from 'styled-components'

import { amountFormatter, PRODUCT_SYMBOLS } from '../utils'

import Gallery from './Gallery'

export default function Card({ totalSupply, dollarPrice, reserveSOCKSToken, chosenProductSymbol }) {
  return (
    <CardWrapper>
      <Gallery chosenProductSymbol={chosenProductSymbol} />
      {chosenProductSymbol === PRODUCT_SYMBOLS.SICK && (
        <Announcement>
          100% of the profits generated from the sale of the Saint Fame Face Mask will be donated to{' '}
          <a href="https://bitpay.com/520663/donate">Red Cross in Crypto</a> to support those impacted by COVID-19.
        </Announcement>
      )}

      <ColoredLine color="#353535" />
      <MarketData>
        <span>
          <Title>
            {chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
              <strong>SAINT FAME: Genesis Shirt</strong>
            ) : (
              <strong>SAINT FAME: $ICK Mask</strong>
            )}
          </Title>
        </span>

        <span>
          <Title style={{ textAlign: 'right' }}>
            {dollarPrice ? `$${amountFormatter(dollarPrice, 18, 2)} USD` : '...'}
          </Title>
        </span>
      </MarketData>
      <MarketData>
        <span>{chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? <Title>$FAME</Title> : <Title>$ICK</Title>}</span>
        <span style={{ textAlign: 'right' }}>
          <Title>{reserveSOCKSToken ? `${amountFormatter(reserveSOCKSToken, 18, 0)} left` : '...'}</Title>
          <Title>{totalSupply ? `${totalSupply} total` : '...'}</Title>
        </span>
      </MarketData>
      <ColoredLine color="#353535" />
    </CardWrapper>
  )
}

const ColoredLine = ({ color }) => (
  <hr
    style={{
      width: '100%',
      color: color,
      backgroundColor: color,
      borderColor: color,
      height: 0.5,
      marginBottom: '30px'
    }}
  />
)

const Announcement = styled.div`
  background: #ffffff;

  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`

const CardWrapper = styled.div`
  /* max-width: 300px; */
  background: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: default;
`

const Title = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  color: #fffafa;
  width: 100%;
  margin: 0;
`

const MarketData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 1rem;
`
