import React from 'react'
import styled, { createGlobalStyle, ThemeProvider as StyledComponentsThemeProvider, keyframes } from 'styled-components'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Tenor+Sans');
  @import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
  
  body {
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Tenor Sans, sans-serif;
    background-color: #000000;
  }
`

export const saintfameGreen = '#24FF00'
export const saintfamePurple = '#FF00D6'

const theme = {
  primary: saintfameGreen,
  secondary: saintfamePurple,
  text: '#000',
  textDisabled: '#737373',
  orange: '#CF2C0A',
  grey: '#F1F2F6',
  blue: '#2F80ED',
  white: '#FFF',
  black: '#000',
  modalGrey: '#242424'
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.img`
  animation: 2s ${rotate} linear infinite;
  width: 16px;
  height: 16px;
`

export function ThemeProvider({ children }) {
  return <StyledComponentsThemeProvider theme={theme}>{children}</StyledComponentsThemeProvider>
}
