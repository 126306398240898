import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { useAppContext } from '../../context'
import { Redirect } from 'react-router-dom'
import { Header } from '../Body'
import Button from '../../components/Button'
import { Link } from '../../components/Works'
import { PRODUCT_SYMBOLS } from '../../utils'

const OrderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2rem;
  border: 1px solid black;
  margin-bottom: 1rem;

  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  color: #fffafa;
`

const NoOrders = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #fffafa;
`

export default function Body({ totalSupply, ready, balanceSOCKS, chosenProductSymbol }) {
  const [state] = useAppContext()
  const { library, account } = useWeb3React()

  const [signature, setSignature] = useState()
  const [timestamp, setTimestamp] = useState()

  const [data, setData] = useState()
  const [error, setError] = useState()

  function sign() {
    const timestampToSign = Math.round(Date.now() / 1000)
    const signer = library.getSigner()
    const message = `This signature is proof that I control the private key of ${account} as of the timestamp ${timestampToSign}.\n\n It will be used to access my SaintFame order history.`
    signer.signMessage(message).then(returnedSignature => {
      setTimestamp(timestampToSign)
      setSignature(returnedSignature)
    })
  }

  useEffect(() => {
    if (account && signature && timestamp) {
      fetch('/.netlify/functions/getEntries', {
        method: 'POST',
        body: JSON.stringify({
          address: account,
          signature: signature,
          timestamp: timestamp,
          chosenProduct: chosenProductSymbol
        })
      }).then(async response => {
        if (response.status !== 200) {
          const parsed = await response.json().catch(() => ({ error: 'Unknown Error' }))
          console.error(parsed.error)
          setError(parsed.error)
        } else {
          const parsed = await response.json()
          setData(parsed)
        }
      })

      return () => {
        setError()
        setData()
        setTimestamp()
        setSignature()
      }
    }
  }, [account, chosenProductSymbol, signature, timestamp])

  if (!account) {
    return <Redirect to={'/'} />
  } else {
    return (
      <AppWrapper overlay={state.visible}>
        <Header
          totalSupply={totalSupply}
          ready={ready}
          balanceSOCKS={balanceSOCKS}
          setShowConnect={() => {}}
          showBackButton={true}
          chosenProductSymbol={chosenProductSymbol}
        />
        <Content>
          <Text>
            You can use this page to check the status of your Saint Fame order, please bookmark it for future reference.
          </Text>
          {error && <p>Error</p>}
          {!data && <Button text={'Access my order history'} onClick={sign} />}
          <br />
          {data &&
            (data.length === 0 ? (
              <NoOrders>No orders found.</NoOrders>
            ) : (
              data.map((d, i) => {
                return (
                  <>
                    <OrderDiv key={i}>
                      Order Date:{' '}
                      {new Date(Number(d.timestamp) * 1000).toLocaleDateString(undefined, {
                        dateStyle: 'long',
                        timeStyle: 'short'
                      })}
                      <br />
                      {chosenProductSymbol} Redeemed: {d.numberOfShirts}
                      {chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
                        <>
                          <br /> Shirt sizes: {d.shirts.join(', ')}{' '}
                        </>
                      ) : (
                        ''
                      )}
                      <br />
                      Status: {d.matched ? (d.shippingId ? 'Shipped!' : 'Processing Order') : 'Order Received'}
                      <br />
                      {d.shippingId && (
                        <>
                          Shipping Id:
                          <Email
                            href={`https://www.google.com/search?q=${d.shippingId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {d.shippingId}
                          </Email>
                        </>
                      )}
                      {d.NFTTransactionHash && (
                        <Link
                          style={{ marginBottom: '.5rem' }}
                          href={`https://etherscan.io/tx/${d.NFTTransactionHash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View ERC-721 Transaction
                        </Link>
                      )}
                    </OrderDiv>
                    <ColoredLine color="#353535" />
                  </>
                )
              })
            ))}
          <Problem>
            Problem with an order?{'—'}
            {'  '}
            <Email
              href={`mailto:orders@saintfame.com?Subject=Genesis%20T-shirt%20Order%20for%20${account}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Email us
            </Email>
            .
          </Problem>
        </Content>
      </AppWrapper>
    )
  }
}

const Text = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  margin-bottom: 30px;
`

const Problem = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
`

const Email = styled.a`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: underline;
`

const AppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  margin: 0px auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  overflow: ${props => (props.overlay ? 'hidden' : 'scroll')};
  scroll-behavior: smooth;
  position: ${props => (props.overlay ? 'fixed' : 'initial')};
`

const Content = styled.div`
  width: calc(100vw - 32px);
  max-width: 580px;
  margin-top: 0px;
  color: white;
`

const ColoredLine = ({ color }) => (
  <hr
    style={{
      width: '100%',
      color: color,
      backgroundColor: color,
      borderColor: color,
      height: 0.5,
      marginBottom: '30px',
      marginTop: '30px'
    }}
  />
)
