import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'

import { useAppContext } from '../context'
import Button from './Button'
import RedeemForm from './RedeemForm'
import { amountFormatter, PRODUCT_SYMBOLS } from '../utils'

import IncrementToken from './IncrementToken'
import shirt from './Gallery/genesis.gif'
import mask from './Gallery/mask.gif'

import close from './Gallery/close.svg'
import Confetti from 'react-dom-confetti'
import { saintfameGreen, saintfamePurple } from '../theme'

const config = {
  angle: 90,
  spread: 76,
  startVelocity: 51,
  elementCount: 154,
  dragFriction: 0.1,
  duration: 7000,
  stagger: 0,
  width: '10px',
  height: '10px',
  colors: ['#fff', saintfameGreen, saintfamePurple]
}

export function Controls({ closeCheckout, theme, type }) {
  return (
    <FrameControls>
      <Close src={close} onClick={() => closeCheckout()} alt="close" />
    </FrameControls>
  )
}

export default function Redeem({
  burn,
  balanceSOCKS,
  balance,
  ready,
  unlock,
  dollarize,
  setCurrentTransaction,
  setShowConnect,
  closeCheckout
}) {
  const { library, account, activate, injected } = useWeb3React()
  const [state, setState] = useAppContext()

  const [numberBurned, setNumberBurned] = useState()
  const [hasPickedAmount, setHasPickedAmount] = useState(false)
  const [hasConfirmedAddress, setHasConfirmedAddress] = useState(false)
  const [transactionHash, setTransactionHash] = useState('')
  const [lastTransactionHash, setLastTransactionHash] = useState('')

  const [hasBurnt, setHasBurnt] = useState(false)
  const [userAddress, setUserAddress] = useState('')

  const [disabledPlaceOrderButton, setDisabledPlaceOrderButton] = useState(false)
  const pending = !!transactionHash

  console.log('userAddress', userAddress)

  useEffect(() => {
    if (transactionHash) {
      library.waitForTransaction(transactionHash).then(() => {
        setLastTransactionHash(transactionHash)
        setTransactionHash('')
        setDisabledPlaceOrderButton(false)
        setHasBurnt(true)
      })
    }
  })

  useEffect(() => {
    if (hasPickedAmount) {
      setState(state => ({ ...state, viewingRedeemForm: !state.viewingRedeemForm }))
    }
  }, [hasConfirmedAddress, hasPickedAmount]) // eslint-disable-line react-hooks/exhaustive-deps

  function link(hash) {
    return `https://etherscan.io/tx/${hash}`
  }

  function renderContent() {
    if (account === undefined) {
      return (
        <ButtonFrame
          className="button"
          disabled={false}
          text={account === undefined ? 'Connect Wallet' : `Redeem ${state.chosenProductSymbol}`}
          type={'cta'}
          onClick={() => {
            activate(injected, undefined, true).catch(() => {
              setShowConnect(true)
            })
          }}
        />
      )
    } else if (!hasPickedAmount) {
      return (
        <>
          <TopFrame hasPickedAmount={hasPickedAmount}>
            <Column>
              <Row>
                {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
                  <ImgStyle src={shirt} alt="Logo" hasPickedAmount={hasPickedAmount} />
                ) : (
                  <ImgStyle src={mask} alt="Logo" hasPickedAmount={hasPickedAmount} />
                )}
              </Row>
              <Row>
                <InfoFrame pending={pending}>
                  <Owned>
                    <SockCount>
                      You own {balanceSOCKS && `${amountFormatter(balanceSOCKS, 18, 0)}`} {state.chosenProductSymbol}
                    </SockCount>
                    <p>
                      Redeem 1 {state.chosenProductSymbol} and you will be sent one{' '}
                      {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? 'real shirt' : 'real mask'}, shipped
                      anywhere in the world.
                    </p>
                  </Owned>
                  <IncrementToken
                    initialValue={Number(amountFormatter(balanceSOCKS, 18, 0))}
                    max={Number(amountFormatter(balanceSOCKS, 18, 0))}
                  />
                </InfoFrame>
              </Row>
              <Row>
                <ButtonFrame
                  className="button"
                  disabled={false}
                  text={'Next'}
                  type={'redeem'}
                  onClick={() => {
                    setNumberBurned(state.count)
                    setHasPickedAmount(true)
                  }}
                />
              </Row>
              <ShowOnlyMobile>
                <Row style={{ margin: '0px', paddingTop: '50px' }}>
                  <span>
                    <ButtonFrame
                      className="button"
                      type={'cancel'}
                      text="Cancel"
                      style={{ width: '280px' }}
                      onClick={() => {
                        closeCheckout()
                      }}
                    />
                  </span>
                </Row>
              </ShowOnlyMobile>
            </Column>
          </TopFrame>
        </>
      )
    } else if (!hasConfirmedAddress) {
      return (
        <>
          <TopFrame hasPickedAmount={hasPickedAmount}>
            {/* <Controls closeCheckout={closeCheckout} type="shipping" /> */}

            <InfoFrame hasPickedAmount={hasPickedAmount}>
              {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
                <ImgStyle src={shirt} alt="Logo" hasPickedAmount={hasPickedAmount} />
              ) : (
                <ImgStyle src={mask} alt="Logo" hasPickedAmount={hasPickedAmount} />
              )}
            </InfoFrame>
          </TopFrame>
          <RedeemFrame
            burn={burn}
            setHasConfirmedAddress={setHasConfirmedAddress}
            setUserAddress={setUserAddress}
            numberBurned={numberBurned}
            chosenProductSymbol={state.chosenProductSymbol}
          />
          <ButtonFrame
            className="button"
            type={'cancel'}
            text="Back"
            style={{ marginLeft: '100px', marginRight: '100px' }}
            onClick={() => {
              setState(state => ({ ...state, viewingRedeemForm: false }))
              setNumberBurned()
              setHasPickedAmount(false)
            }}
          />
        </>
      )
    } else if (!hasBurnt) {
      return (
        <>
          <TopFrame hasPickedAmount={hasPickedAmount}>
            <Column>
              <Row>
                {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
                  <ImgStyle src={shirt} alt="Logo" hasPickedAmount={hasPickedAmount} />
                ) : (
                  <ImgStyle src={mask} alt="Logo" hasPickedAmount={hasPickedAmount} />
                )}
              </Row>

              {/* <InfoFrame hasPickedAmount={hasPickedAmount}> */}

              {/* <Owned>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#ff0000'
                  }}
                >
                  {userAddress}
                </p>
              </Owned> */}
              {/* </InfoFrame> */}
              {/* <InfoFrame hasPickedAmount={hasPickedAmount}>
              <ImgStyle src={nfc} alt="Logo" hasPickedAmount={hasPickedAmount} />
              <Bonus>Bonus</Bonus>
              <Owned>
                <p style={{ fontSize: '18px' }}>{state.count} Unisocks NFT</p>
                <p style={{ fontSize: '14px', fontWeight: '500' }}>Digital Collectible (10kb)</p>
                <p
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#AEAEAE',
                    marginTop: '16px',
                    marginRight: '16px',
                    wordBreak: 'break-all'
                  }}
                >
                  {account}
                </p>
              </Owned>
            </InfoFrame> */}
              <Row>
                <ButtonFrame
                  className="button"
                  disabled={disabledPlaceOrderButton}
                  pending={pending}
                  style={{ marginLeft: '30px', marginRight: '30px' }}
                  text={
                    pending
                      ? `Waiting for confirmation...`
                      : `Place order (Redeem ${numberBurned} ${state.chosenProductSymbol}) `
                  }
                  type={'cta'}
                  onClick={() => {
                    setDisabledPlaceOrderButton(true)
                    burn(numberBurned.toString())
                      .then(response => {
                        setTransactionHash(response.hash)
                      })
                      .catch(error => {
                        setDisabledPlaceOrderButton(false)
                        console.error(error)
                      })
                  }}
                />
              </Row>
              <Row>
                <Back disabled={!!pending}>
                  {pending ? (
                    <EtherscanLink href={link(transactionHash)} target="_blank" rel="noopener noreferrer">
                      View on Etherscan.
                    </EtherscanLink>
                  ) : (
                    <ButtonFrame
                      className="button"
                      type={'cancel'}
                      text="Back"
                      style={{ marginLeft: '100px', marginRight: '100px' }}
                      onClick={() => {
                        setHasConfirmedAddress(false)
                      }}
                    />
                  )}
                </Back>
              </Row>
            </Column>
          </TopFrame>
        </>
      )
    } else {
      return (
        <>
          <TopFrame hasPickedAmount={hasPickedAmount}>
            {/* <Controls closeCheckout={closeCheckout} /> */}
            {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
              <ImgStyle src={shirt} alt="Logo" hasPickedAmount={hasPickedAmount} />
            ) : (
              <ImgStyle src={mask} alt="Logo" hasPickedAmount={hasPickedAmount} />
            )}
            <InfoFrame />
            <Content>
              <CheckoutPromptHeader>Congratulations!</CheckoutPromptHeader>
              <CheckoutPrompt>
                {state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME
                  ? `Your T-shirt's on it's way`
                  : `Your Mask's on it's way`}
                <br />
                Estimated shipping time 2-3 weeks.
                <br />
                Shipping time will vary by region.
              </CheckoutPrompt>
              <CheckoutPrompt>
                Your shipping details can be viewed{' '}
                <CheckoutPromptLink
                  to={state.chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? `fame/status` : `sick/status`}
                >
                  here
                </CheckoutPromptLink>
                .
                <br />
                <EtherscanLink href={link(lastTransactionHash)} target="_blank" rel="noopener noreferrer">
                  View on Etherscan.
                </EtherscanLink>
              </CheckoutPrompt>

              <ButtonFrame
                className="button"
                type={'cancel'}
                text="Close"
                onClick={() => {
                  closeCheckout()
                }}
              />
            </Content>
          </TopFrame>
        </>
      )
    }
  }

  return (
    <>
      {renderContent()}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Confetti active={!hasBurnt} config={config} />
      </div>
    </>
  )
}

const TopFrame = styled.div`
  width: 100%;
  max-width: 780px;
  background: #242424;
  color: ${saintfameGreen};
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  padding-top: 30px;

  @media only screen and (max-width: 580px) {
    width: 580px;
    overflow: scroll;
  }
`
const Content = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
`

const Close = styled.img`
  width: 32px;
  margin-right: -72px;
  height: 32px;
  cursor: pointer;
  padding: 20px;
`

const FrameControls = styled.div`
  position: absolute;
  left: 100%;
  right: 20.83%;
  top: 20.83%;
  bottom: 20.83%;
  background: red;
`

const InfoFrame = styled.div`
  opacity: ${props => (props.pending ? 0.6 : 1)};
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: ${props => (props.hasPickedAmount ? '8px' : '0')};
  justify-content: ${props => (props.hasPickedAmount ? 'flex-start' : 'space-between')};
  align-items: flex-end;
  padding: ${props => (props.hasPickedAmount ? '1rem 0 1rem 0' : ' 0')};
  margin-top: 30px;
`

const Owned = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  margin-bottom: 12px;
  margin-left: 30px;
  margin-top: 0px;
  white-space: pre-wrap;

  @media only screen and (max-width: 580px) {
    margin-left: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
`

const ImgStyle = styled.img`
  width: 400px;
  box-sizing: border-box;
  margin: auto;
`
const SockCount = styled.span`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #24ff00;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.primary};
`

const Back = styled.div`
  color: #aeaeae;
  font-weight: 400;
  margin: 0px;
  margin: -4px 0 16px 0px !important;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: ${props => props.theme.primary}; */
  text-align: center;
  span {
    cursor: pointer;
  }
  :hover {
    text-decoration: underline;
  }
`

const CheckoutPromptHeader = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 60px;
  color: #ffffff;
  margin-left: 100px;
  margin-right: 100px;
  padding-bottom: 30px;
  @media only screen and (max-width: 580px) {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
    line-height: 40px;
  }
`

const CheckoutPrompt = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  margin-left: 100px;
  margin-right: 100px;
  @media only screen and (max-width: 580px) {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 20px;
    line-height: 30px;
  }
`
const CheckoutPromptLink = styled(Link)`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  @media only screen and (max-width: 580px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const ButtonFrame = styled(Button)`
  margin: 16px;
  height: 70px
  padding: 16px;

  @media only screen and (max-width: 580px) {

  }
`

const RedeemFrame = styled(RedeemForm)`
  width: 100%;
`

const EtherscanLink = styled.a`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #24ff00;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: default;
`

const ShowOnlyMobile = styled.span`
  display: none;

  @media only screen and (max-width: 580px) {
    display: block;
  }
`
