import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { ethers } from 'ethers'
import GlobalStyle, { ThemeProvider } from '../theme'
import Web3ReactManager from '../components/Web3ReactManager'
import AppProvider from '../context'
import Main from './Main'
import Status from './Status'
import Home from './Home'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { NetworkContextName, TOKEN_ADDRESSES, PRODUCT_SYMBOLS } from '../utils'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

function getLibrary(provider) {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 20000
  return library
}

export default function App() {
  return (
    <ThemeProvider>
      <>
        <GlobalStyle />
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <AppProvider>
              <Web3ReactManager>
                <BrowserRouter>
                  <Switch>
                    <Route exact strict path="/" render={() => <Home />} />
                    <Route
                      exact
                      strict
                      path="/fame/status"
                      render={() => <Status chosenProductSymbol={PRODUCT_SYMBOLS.FAME} />}
                    />
                    <Route
                      exact
                      strict
                      path="/sick/status"
                      render={() => <Status chosenProductSymbol={PRODUCT_SYMBOLS.SICK} />}
                    />
                    <Route
                      exact
                      strict
                      path="/fame"
                      render={() => (
                        <Main
                          chosenProductSymbol={PRODUCT_SYMBOLS.FAME}
                          chosenProductEthAddress={TOKEN_ADDRESSES.FAME}
                        />
                      )}
                    />

                    <Route
                      exact
                      strict
                      path="/sick"
                      render={() => (
                        <Main
                          chosenProductSymbol={PRODUCT_SYMBOLS.SICK}
                          chosenProductEthAddress={TOKEN_ADDRESSES.SICK}
                        />
                      )}
                    />
                    <Redirect to="/" />
                  </Switch>
                </BrowserRouter>
              </Web3ReactManager>
            </AppProvider>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </>
    </ThemeProvider>
  )
}
