import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../context'
import Card from '../../components/Card'
import BuyButtons from '../../components/Buttons'
import RedeemButton from '../../components/RedeemButton'
import Checkout from '../../components/Checkout'
import { amountFormatter } from '../../utils'
import logo from './logo.svg'
import { saintfameGreen } from '../../theme'
import { injected } from '../../connectors'
import { PRODUCT_SYMBOLS } from '../../utils'

export function Header({ totalSupply, ready, balanceSOCKS, setShowConnect, showBackButton, chosenProductSymbol }) {
  const { account, activate } = useWeb3React()

  function handleAccount() {
    activate(injected, undefined, true).catch(() => {
      setShowConnect(true)
    })
  }

  let symbol = chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? PRODUCT_SYMBOLS.FAME : PRODUCT_SYMBOLS.SICK

  return (
    <>
      {showBackButton && (
        <HideMobile>
          <BackHomepage to="/">← Back to Homepage</BackHomepage>
        </HideMobile>
      )}
      <HeaderFrame balanceSOCKS={balanceSOCKS}>
        <div
          style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', width: '100%', height: '70px' }}
        >
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              flex: '0 1 auto',
              width: '180px',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
          >
            <ImgStyle src={logo} alt="Saint Fame logo" />
          </Link>
          <Account
            onClick={() => handleAccount()}
            balanceSOCKS={balanceSOCKS}
            style={{ flex: '0 1 auto', width: '280px', height: '62px' }}
          >
            {account ? (
              balanceSOCKS > 0 ? (
                <SockCount>
                  {balanceSOCKS && `${amountFormatter(balanceSOCKS, 18, 0)}`} {symbol}
                </SockCount>
              ) : (
                <SockCount>{account.slice(0, 8)}...</SockCount>
              )
            ) : (
              <SockCount>Connect Wallet</SockCount>
            )}

            <Status balanceSOCKS={balanceSOCKS} ready={ready} account={account} />
          </Account>
        </div>
      </HeaderFrame>
    </>
  )
}

const HideMobile = styled.span`
  @media only screen and (max-width: 580px) {
    display: none;
  }
`

const HeaderFrame = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  background-color: black;

  @media only screen and (max-width: 580px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 70px;
  }
`

const Account = styled.div`
  border-style: solid;
  border-width: 4px;
  border-color: ${saintfameGreen};
  color: ${saintfameGreen};
  text-align: center;
  justify-content: center;
  cursor: ${props => (props.balanceSOCKS ? 'auto' : 'pointer')};
  margin-top: 70px;
  margin-left: calc(50% + 300px);
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: ${saintfameGreen};
    background: rgba(36, 255, 0, 0.2);
  }
  :active {
    outline: none;
    opacity: 0.5;
  }

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    width: 280px;
    height: 70px;
    flex: 1;
    margin-left: calc(50% - 140px);
    margin-top: 50px;
  }
`

const BackHomepage = styled(Link)`
  position: fixed;
  left: 130px
  top: 120px;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
`

const SockCount = styled.p`
  font-size: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
`

const Status = styled.div`
  display: ${props => (props.balanceSOCKS ? 'initial' : 'none')};
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-left: 12px;
  margin-top: 2px;
  float: right;
  background-color: ${props =>
    props.account === undefined ? props.theme.orange : props.ready ? props.theme.green : props.theme.orange};
  // props.account === undefined ? props.theme.orange : props.theme.green};
`

const ImgStyle = styled.img`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
`

export default function Body({
  selectedTokenSymbol,
  setSelectedTokenSymbol,
  ready,
  unlock,
  validateBuy,
  buy,
  validateSell,
  sell,
  burn,
  dollarize,
  dollarPrice,
  balanceSOCKS,
  reserveSOCKSToken,
  totalSupply,
  chosenProductSymbol
}) {
  const { account } = useWeb3React()
  const [currentTransaction, _setCurrentTransaction] = useState({})
  const setCurrentTransaction = useCallback((hash, type, amount) => {
    _setCurrentTransaction({ hash, type, amount })
  }, [])
  const clearCurrentTransaction = useCallback(() => {
    _setCurrentTransaction({})
  }, [])
  const [state, setState] = useAppContext()
  const [showConnect, setShowConnect] = useState(false)
  const [showWorks, setShowWorks] = useState(false)
  const [showBackButton, setShowBackButton] = useState(false)

  console.log('setShowBackButton', setShowBackButton)

  return (
    <AppWrapper overlay={state.visible}>
      <Header
        totalSupply={totalSupply}
        ready={ready}
        dollarPrice={dollarPrice}
        balanceSOCKS={balanceSOCKS}
        setShowConnect={setShowConnect}
        showBackButton={showBackButton}
        chosenProductSymbol={chosenProductSymbol}
      />
      <Content>
        <Card
          totalSupply={totalSupply}
          dollarPrice={dollarPrice}
          reserveSOCKSToken={reserveSOCKSToken}
          chosenProductSymbol={chosenProductSymbol}
        />
        <BuyButtons balanceSOCKS={balanceSOCKS} />
        <RedeemButton balanceSOCKS={balanceSOCKS} chosenProductSymbol={chosenProductSymbol} />
        {/* {console.warn('Add Check order status? back in when Redeem is working')} */}
        {/* {!!account && (
          <Link style={{ textDecoration: 'none' }} to="/status">
            <OrderStatusLink>Check order status?</OrderStatusLink>
          </Link>
        )} */}
        {!!account && (
          <Link to={chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? '/fame/status' : '/sick/status'}>
            <StatusLink>View Order status</StatusLink>
          </Link>
        )}
        <Info>
          {chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
            <>
              <Section>
                <ColoredLine color="#353535" />
                Product details:
                <br />
                • Black long sleeve crew neck
                <br />
                • Made in Los Angeles, USA.
                <br />
                • White screen printed graphic
                <br />
                • Puff print on sleeves
                <br />
                • 6.5 oz, 100% Cotton
                <br />• Unisex, classic-style fit
                <ColoredLine color="#353535" />
              </Section>
              <Hero>An experiment in "Speculative Fashion"</Hero>

              <Section>
                Buy and sell the <i>"Saint Fame: Genesis Shirt"</i> with cryptocurrency.
              </Section>

              <Section>
                This is the first fashion piece to be produced by Saint Fame—the world's first internet-owned fashion
                house.
              </Section>

              <Section>
                Matt Vernon (a.k.a. <Links href="https://twitter.com/dapp_boi">Dapp Boi</Links>) was commissioned by
                Saint Fame using his
                <Links href="https://dappboi.com"> $BOI token</Links>. Saint Fame{' '}
                <Links href="https://etherscan.io/tx/0x3798e499d12dcbb4373b049c175bc65c515760430d94f369c3fe64d275397b73">
                  bought 8 $BOI
                </Links>
                —worth 8 hours of Matt’s time—to have him produce a design for this shirt.
              </Section>

              <Section>
                In Matt's words, the piece captures <i>“a somewhat incoherent explosion of weird internet things”</i>{' '}
                that powers Saint Fame.
              </Section>

              <Section>
                $FAME tokens were launched, and able to be bought and sold <i>before</i> the design of the shirt was
                known. Once Matt submitted his work, Saint Fame{' '}
                <Links href="https://mainnet.aragon.org/#/sf/0x4d2e79c0df917721f082bc1fd7322356962d1eb6/vote/14/">
                  collectively voted to approve it
                </Links>
                . Saint Fame will now manufacture the shirt which $FAME tokens will ultimately be redeemable for.
              </Section>

              <Section>Anyone can continue to buy and sell the $FAME token.</Section>

              <Section>
                Purchasing exactly 1 $FAME will entitle you to one real shirt, shipped anywhere in the world.
              </Section>

              <Section>
                <Links
                  href="/"
                  onClick={e => {
                    e.preventDefault()
                    setState(state => ({ ...state, visible: !state.visible }))
                    setShowWorks(true)
                  }}
                >
                  Learn more
                </Links>
              </Section>
            </>
          ) : (
            <>
              <Section>
                <ColoredLine color="#353535" />
                Product details:
                <br />
                • Neoprene face mask
                <br />
                • One size fits most
                <br />
                • Fully Lined in Poly Spandex jersey
                <br />
                • Velcro attachments
                <br />
                • 1/4" Contrast cross-cut poly spandex binding attached with Zig Zag stitch
                <ColoredLine color="#353535" />
              </Section>
              <Section>
                The $ICK Mask is a face mask by Saint Fame, the world's first internet-owned fashion house. It is the
                first piece collectively designed by the disciples of Saint Fame.
              </Section>

              <Section>
                Purchasing exactly 1 $ICK will entitle you to one real face mask, shipped anywhere in the world.
              </Section>
              <Section>
                Anyone can buy and sell the $ICK token. The design was released on Monday 26th of March at 2pm PT.
              </Section>
              <Section>
                $ICK will be redeemable for the physical face masks for up to 1 year after redemptions are enabled.
              </Section>
              <Section>
                These masks are not medically certified. Check your local health organisations for mask safety
                guidelines.
              </Section>
            </>
          )}

          <Section>
            <Links href="https://twitter.com/SAINTFAMEdao">Twitter</Links> —{' '}
            <Links href="https://www.instagram.com/saint.fame/">Instagram</Links>
          </Section>
          <Section>
            <Links href="https://ourzora.com">
              <span role="img" aria-label="sun moon sun">
                🌜🌞🌛
              </span>
            </Links>
          </Section>
          {/* <SubInfo>
            An experiment in pricing and user experience by the team at Uniswap.{' '}
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                setState(state => ({ ...state, visible: !state.visible }))
                setShowWorks(true)
              }}
            >
              How it works.
            </a>
          </SubInfo> */}
        </Info>
      </Content>
      <Checkout
        selectedTokenSymbol={selectedTokenSymbol}
        setSelectedTokenSymbol={setSelectedTokenSymbol}
        ready={ready}
        unlock={unlock}
        validateBuy={validateBuy}
        buy={buy}
        validateSell={validateSell}
        sell={sell}
        burn={burn}
        balanceSOCKS={balanceSOCKS}
        dollarPrice={dollarPrice}
        reserveSOCKSToken={reserveSOCKSToken}
        dollarize={dollarize}
        showConnect={showConnect}
        setShowConnect={setShowConnect}
        currentTransactionHash={currentTransaction.hash}
        currentTransactionType={currentTransaction.type}
        currentTransactionAmount={currentTransaction.amount}
        setCurrentTransaction={setCurrentTransaction}
        clearCurrentTransaction={clearCurrentTransaction}
        showWorks={showWorks}
        setShowWorks={setShowWorks}
        totalSupply={totalSupply}
        chosenProductSymbol={chosenProductSymbol}
      />
    </AppWrapper>
  )
}

const Links = styled.a`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  text-decoration: underline;

  :visited,
  :link,
  :hover,
  :active {
    color: ${props => props.theme.primary};
  }
`

const StatusLink = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
  justify-content: center;
  margin-top: 30px;
  height: 62px;
  text-align: center;
  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    width: 280px;
    height: 70px;
    flex: 1;
    margin-left: calc(50% - 140px);
    margin-top: 50px;
  }
`

const AppWrapper = styled.div`
  width: 100vw;
  height: 100%;
  margin: 0px auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  overflow: ${props => (props.overlay ? 'hidden' : 'scroll')};
  scroll-behavior: smooth;
  position: ${props => (props.overlay ? 'fixed' : 'initial')};
`

const Section = styled.div`
  margin-bottom: 35px;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 40px;
  color: #fffafa;
`

const Content = styled.div`
  width: calc(100vw - 32px);
  max-width: 580px;
  margin-top: 0px;
  a:nth-child(even) {
    color: ${props => props.theme.primary};
  }
  a:nth-child(odd) {
    color: ${props => props.theme.secondary};
  }

  a {
    text-decoration: none;
    /* padding-top: 8px; */
    /* font-size: 14px; */
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Info = styled.div`
  color: #fffafa;
  font-weight: 500;
  margin: 0px;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 20px;
  padding-top: 32px;
  border-radius: 0 0 8px 8px;
  /* border-radius: 8px; */
  margin-bottom: 12px;
  margin-top: -12px;
  /* margin-top: 16px; */
`

const Hero = styled.div`
  font-size: 48px;
  margin-bottom: 30px;
  line-height: 60px;
`

const ColoredLine = ({ color }) => (
  <hr
    style={{
      width: '100%',
      color: color,
      backgroundColor: color,
      borderColor: color,
      height: 0.5,
      marginBottom: '30px'
    }}
  />
)
