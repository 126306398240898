import React from 'react'
import styled from 'styled-components'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core' // Updated from v5 web3-react, but not hooked up yet

import Button from './Button'
import { useAppContext } from '../context'
import { TRADE_TYPES } from '../utils'

const BuyButtonFrame = styled.div`
  margin: 0.5rem 0rem 0.5rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: center;
  flex-direction: row;
  color: ${props => props.theme.black};

  div {
    width: 100%;
  }

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    flex-direction: column;
  }
`
const ButtonFrame = styled(Button)`
  width: 100%;
`

const Shim = styled.div`
  width: 1rem !important;
  height: 1rem;
`

export default function RedeemButton({ balanceSOCKS, chosenProductSymbol }) {
  const [, setState] = useAppContext()
  const { account } = useWeb3React()

  // function sendEmail() {
  //   const {
  //     REACT_APP_MAILGUN_API_KEY,
  //     REACT_APP_MAILGUN_DOMAIN,
  //     REACT_APP_MAILGUN_URL,
  //     REACT_APP_FROM_EMAIL_ADDRESS,
  //     REACT_APP_CONTACT_TO_EMAIL_ADDRESS
  //   } = process.env

  //   console.log('MAILGUN_API_KEY', REACT_APP_MAILGUN_API_KEY)
  //   console.log('MAILGUN_DOMAIN', REACT_APP_MAILGUN_DOMAIN)
  //   console.log('MAILGUN_API_KEY', REACT_APP_MAILGUN_URL)
  //   console.log('MAILGUN_DOMAIN', REACT_APP_FROM_EMAIL_ADDRESS)
  //   console.log('MAILGUN_API_KEY', REACT_APP_CONTACT_TO_EMAIL_ADDRESS)

  //   fetch('/.netlify/functions/send-email', {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     method: 'POST',
  //     body: JSON.stringify({
  //       contactName: 'Dai ',
  //       contactEmail: 'Derp@derp.co',
  //       message: 'MESSAGE'
  //     })
  //   })
  //     .then(() => {
  //       console.log('Email sent')
  //     })
  //     .catch(console.error)
  // }

  function handleToggleCheckout(tradeType) {
    setState(state => ({ ...state, visible: !state.visible, tradeType, chosenProductSymbol }))
  }

  return (
    <BuyButtonFrame>
      <ButtonFrame
        disabled={balanceSOCKS > 0 ? false : true}
        text={'Sell'}
        type={'secondary'}
        onClick={() => {
          handleToggleCheckout(TRADE_TYPES.SELL)
        }}
      />
      <Shim />
      <ButtonFrame
        disabled={
          account === undefined ||
          !balanceSOCKS ||
          balanceSOCKS.lt(ethers.utils.bigNumberify(10).pow(ethers.utils.bigNumberify(18)))
        }
        text={'Redeem'}
        type={'secondary'}
        onClick={() => {
          handleToggleCheckout(TRADE_TYPES.REDEEM)
        }}
      />
    </BuyButtonFrame>
  )
}
