import React from 'react'
import styled from 'styled-components'
import genesis from './genesis.gif'
import { PRODUCT_SYMBOLS } from '../../utils'
import mask from './mask.gif'

const GalleryFrame = styled.div`
  width: 100%;
  position: relative;
  height: 80%;
  min-height: 258px;
  display: inline-block;
  align-items: center;
  flex-direction: center;
  justify-content: center;
`

const ImgStyle = styled.img`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
`

export default function Gallery({ chosenProductSymbol }) {
  return (
    <GalleryFrame>
      {chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
        <ImgStyle src={genesis} alt="Saint Fame T-shirt" />
      ) : (
        <ImgStyle src={mask} alt="Saint Fame Mask" />
      )}
    </GalleryFrame>
  )
}
