import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import QRCode from 'qrcode.react'

import Button from './Button'
import { Controls } from './Redeem'

import { fortmatic, walletlink, walletconnect } from './../connectors'

export default function Connect({ setShowConnect, closeCheckout }) {
  const { account, connector, activate, injected } = useWeb3React()

  // connector error
  const [connectorError, setConnectorError] = useState()

  function activateInjected() {
    activate(injected, undefined, true).catch(error => {
      setConnectorError(error)
    })
  }

  function activateWalletConnect() {
    activate(walletconnect, undefined, true).catch(error => {
      console.log('error', error)
      setConnectorError(error)
    })
  }

  function activateFortmatic() {
    activate(fortmatic, undefined, true).catch(error => {
      setConnectorError(error)
    })
  }

  function activateCoinbaseWallet() {
    activate(walletlink, undefined, true).catch(error => {
      setConnectorError(error)
    })
  }

  const walletconnectUri = connector && connector.walletConnector && connector.walletConnector.uri

  // unset the error on connector change
  useEffect(() => {
    setConnectorError()
  }, [connector])

  // once an account is connected, don't show this screen
  useEffect(() => {
    if (account !== undefined) {
      setShowConnect(false)
    }
  })

  return (
    <>
      <Controls closeCheckout={closeCheckout} />
      <WalletFrame>
        <Title>Select wallet</Title>
        <Button
          style={{ margin: '0px', width: '280px', marginTop: '70px' }}
          type={'buysell'}
          text="MetaMask"
          onClick={() => {
            activateInjected()
          }}
        />
        <Button
          style={{ margin: '0px', width: '280px', marginTop: '24px' }}
          type={'buysell'}
          text="WalletConnect"
          onClick={() => {
            activateWalletConnect()
          }}
        />
        <Button
          style={{ margin: '0px', width: '280px', marginTop: '24px' }}
          type={'buysell'}
          text="Fortmatic"
          onClick={() => {
            activateFortmatic()
          }}
        />
        <Button
          style={{ margin: '0px', width: '280px', marginTop: '24px' }}
          type={'buysell'}
          text="Coinbase Wallet"
          onClick={() => {
            activateCoinbaseWallet()
          }}
        />
        <QRCodeWrapper showQR={walletconnectUri && account === undefined && !connectorError}>
          {walletconnectUri && account === undefined && !connectorError ? (
            <>
              <QRCode value={walletconnectUri} />
              <p>Scan to connect</p>
            </>
          ) : null}
        </QRCodeWrapper>
        {connectorError ? (
          <p style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
            {}
            {'Connection Error (Try a web3-enabled browser or a different wallet).  '}
            <Link href="https://ethereum.org/wallets/">Learn more</Link>
          </p>
        ) : (
          <Title style={{ marginTop: '50px', marginBottom: '100px' }}>
            Don't have one? <Link href="https://ethereum.org/wallets/">Learn more</Link>
          </Title>
        )}
        <ShowOnlyMobile>
          <span style={{ paddingTop: '30px', width: '280px' }}>
            <ButtonFrame
              className="button"
              type={'cancel'}
              text="Cancel"
              style={{}}
              onClick={() => {
                closeCheckout()
              }}
            />
          </span>
        </ShowOnlyMobile>
      </WalletFrame>
    </>
  )
}

const ButtonFrame = styled(Button)`
  width: 280px;
  height: 70px;
`

const ShowOnlyMobile = styled.span`
  display: none;

  @media only screen and (max-width: 580px) {
    display: block;
  }
`

const Title = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 35px;

  text-align: center;
  color: #fffafa;
  margin-top: 50px;
`

const WalletFrame = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: default;
  justify-content: center;
  align-items: center;
  background-color: #242424;

  @media only screen and (max-width: 580px) {
    width: 580px;
    overflow: scroll;
    height: 100vh;
    flex-wrap: nowrap;
  }
`

const QRCodeWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${props => (props.showQR ? '1rem' : 0)};
`

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
`
