import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import close from './Gallery/close.svg'

const WorksFrame = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #242424;

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    width: 780px;
    overflow: scroll;
    height: 100vh;
  }
`
const Title = styled.p`
  margin-top: 1rem !important;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #fffafa;
`

const Desc = styled.p`
  line-height: 150%;
  margin-top: 1rem !important;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #fffafa;
`

export function link(hash) {
  return `https://etherscan.io/tx/${hash}`
}

export const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 8px;
`

const Close = styled.img`
  width: 16px;
  color: #fff;
  font-weight: 600;
  margin: 0px;
  /* margin-right: 2px;
  margin-top: -7px; */
  height: 16px;
  font-size: 16px;
  padding: 4px;
  cursor: pointer;
`

const FrameControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  padding-top: 70px;
`

export function Controls({ closeCheckout, theme, type }) {
  return (
    <FrameControls>
      <Close src={close} onClick={() => closeCheckout()} alt="close" />
    </FrameControls>
  )
}

export default function Works({ closeCheckout }) {
  return (
    <WorksFrame>
      {/* <HideMobile>
        <Controls closeCheckout={closeCheckout} />
      </HideMobile> */}
      <Title>How it works:</Title>
      <Desc>$FAME is a token that entitles you to 1 real limited edition t-shirt, shipped anywhere in the world.</Desc>
      <Desc>
        You can sell the token back at any time. To get a <i>real</i> shirt, redeem a $FAME token
      </Desc>
      <Title>How it's priced:</Title>
      <Desc>
        $FAME tokens are listed starting at $8 USD. Each buy/sell will move the price. The increase or decrease follows
        a{' '}
        <Link
          href="https://blog.relevant.community/bonding-curves-in-depth-intuition-parametrization-d3905a681e0a"
          target="_blank"
          rel="noopener noreferrer"
        >
          bonding curve
        </Link>
        . FAME will eventually find an equillibrium based on market demand.
      </Desc>
      <Title>Uniswap:</Title>
      <Desc>
        Buying or selling FAME uses the uniswap protocol and accepts any token input as a payment method. The pool of
        $FAME is a uniswap pool where 100 $FAME tokens were deposited along with the starting value of ETH.
      </Desc>
      <Desc>
        <Link href="https://docs.uniswap.io/" target="_blank" rel="noopener noreferrer">
          Learn more about how uniswap works.
        </Link>
      </Desc>
      <Title>Saint Fame:</Title>
      <Desc>
        Saint Fame is the world's first internet-owned fashion house. Disciples of Saint Fame are voted into the
        organization, and collectively commission creators to product work under the Saint Fame brand.
      </Desc>
      <Desc>
        <Link
          href="https://mainnet.aragon.org/#/sf/0x4d2e79c0df917721f082bc1fd7322356962d1eb6/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saint Fame is powered by Aragon.
        </Link>
      </Desc>
      <Desc>
        <Link href="mailto:hello@saintfame.com" target="_blank" rel="noopener noreferrer">
          Get in touch.
        </Link>
      </Desc>
      <ShowOnlyMobile>
        <span>
          <ButtonFrame
            className="button"
            type={'cancel'}
            text="Close"
            style={{ width: '280px', marginTop: '50px' }}
            onClick={() => {
              closeCheckout()
            }}
          />
        </span>
      </ShowOnlyMobile>
    </WorksFrame>
  )
}

const ButtonFrame = styled(Button)`
  height: 70px;
`

const ShowOnlyMobile = styled.span`
  display: none;

  @media only screen and (max-width: 580px) {
    display: flex;
    justify-content: center;
  }
`
