import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../pages/Body/logo.svg'
import styled from 'styled-components'
import fame from '../../components/Gallery/genesis.gif'
import mask from '../../components/Gallery/mask.gif'

export default function Home() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          position: 'relative',
          width: '100%',
          height: '70px',
          marginTop: '50px'
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: 'none',
            flex: '0 1 auto',
            width: '180px',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <ImgStyle src={logo} alt="Saint Fame logo" />
        </Link>
      </div>
      <Row>
        <TextLink to="/fame">
          <ImgLeft src={fame} alt="Saint Fame shirt" />
          <TextLeft>$FAME</TextLeft>
        </TextLink>
        <TextLink to="/sick">
          <ImgRight src={mask} alt="Saint Fame mask" />
          <TextRight>$ICK</TextRight>
        </TextLink>
      </Row>
    </>
  )
}

const Text = styled.div`
  text-align: center;
  width: 380px;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #fffafa;
`
const TextLink = styled(Link)`
  text-decoration: none;
  color: red;

  :active {
    text-decoration: none;
    color: #fffafa;
  }
  :hover {
    text-decoration: underline;
    color: #fffafa;
  }
  :visted {
    text-decoration: none;
    color: #fffafa;
  }
`

const TextLeft = styled(Text)`
  margin-right: 110px;
  @media only screen and (max-width: 580px) {
    margin-right: 0px;
    width: 100%;
  }
`

const TextRight = styled(Text)`
  margin-left: 110px;
  @media only screen and (max-width: 580px) {
    margin-left: 0px;
    width: 100%;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const ImgLeft = styled.img`
  width: 380px;
  height: 380px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 110px;
  @media only screen and (max-width: 580px) {
    margin-right: 0px;
    width: 100%;
  }
`

const ImgRight = styled.img`
  width: 380px;
  height: 380px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 110px;
  @media only screen and (max-width: 580px) {
    margin-left: 0px;
    width: 100%;
  }
`

const ImgStyle = styled.img`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
`
