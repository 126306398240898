import React, { useState, useContext } from 'react'

import { TRADE_TYPES, PRODUCT_SYMBOLS } from '../utils'

export const AppContext = React.createContext([{}, () => {}])

const initialState = {
  visible: false,
  count: 1,
  valid: false,
  tradeType: TRADE_TYPES.BUY,
  viewingRedeemForm: false,
  chosenProductSymbol: PRODUCT_SYMBOLS.SICK
}

export default function AppProvider({ children }) {
  const [state, setState] = useState(initialState)

  return <AppContext.Provider value={[state, setState]}>{children}</AppContext.Provider>
}

export function useAppContext() {
  return useContext(AppContext)
}
