import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../utils'
import arrow from './Gallery/arrow.svg'

const SelectMenu = styled.select`
  background-color: #242424;
  color: #fffafa;
  display: block;
  border-radius: 0px;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  display: flex;
  flex-direction: row;
  border-color: white;
  color: white;
  border-style: solid;
  border-width: 4px;
  padding-left: 20px;
  pad :active {
    outline: none;
    background: #444;
    opacity: 0.5;
  }
  :hover {
    background: #666;
    color: white;
  }
`

const SelectItem = styled.option`
  border: none;
  width: 100%;
  padding: 0px 0.5rem 0px 0.5rem;
`

const options = Object.keys(SIZES).map(s => ({ value: s, label: s }))

function renderOptions(token, i, selectedShirtSize) {
  if (i === 0) {
    return (
      <SelectItem disabled selected key={i} value={token.value}>
        {token.label}
      </SelectItem>
    )
  }
  return (
    <SelectItem key={i} value={token.value}>
      {token.label}
    </SelectItem>
  )
}

export default function SelectShirtSize({ selectedShirtSize, value, name, handleChange }) {
  return (
    <>
      <SelectMenu value={value} name={name} onChange={handleChange} className="dropdown">
        {options.map((item, i) => renderOptions(item, i, selectedShirtSize))}
      </SelectMenu>

      <NoHeight>
        <DropControl src={arrow} alt="dropdown-arrow" />
      </NoHeight>
    </>
  )
}

const NoHeight = styled.div`
  height: 0px;
  position: relative;
  top: -45px;
  left: calc(100% - 42px);
`

const DropControl = styled.img`
  width: 22px;
`
