import React from 'react'
import styled from 'styled-components'

import { TOKEN_SYMBOLS, PRODUCT_SYMBOLS } from '../utils'

import arrow from './Gallery/arrow.svg'

const SelectMenu = styled.select`
  background-color: #242424;
  color: #fffafa;
  display: block;
  border-radius: 0px;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  width: 280px;
  height: 70px;
  max-width: 280px;
  box-sizing: border-box;
  margin: 0;
  appearance: none;
  display: flex;
  flex-direction: row;
  border-color: white;
  color: white;
  border-style: solid;
  border-width: 4px;
  padding-left: 20px;
  pad :active {
    outline: none;
    background: #444;
    opacity: 0.5;
  }
  :hover {
    background: #666;
    color: white;
  }
`

const SelectItem = styled.option`
  border: none;
  width: 100%;
  padding: 0px 0.5rem 0px 0.5rem;
`

function renderOptions(token, i, selectedTokenSymbol, prefix) {
  if (selectedTokenSymbol === token.value) {
    return (
      <SelectItem key={i} value={token.value}>
        {prefix + ' ' + token.label}
      </SelectItem>
    )
  } else {
    return (
      <SelectItem key={i} value={token.value}>
        {token.label}
      </SelectItem>
    )
  }
}

export default function SelectToken({ selectedTokenSymbol, setSelectedTokenSymbol, prefix, chosenProductSymbol }) {
  let symbol = chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? 'FAME' : 'SICK'

  let options = Object.keys(TOKEN_SYMBOLS)
    .filter(s => s !== symbol)
    .map(s => ({ value: s, label: s }))

  return (
    <>
      <SelectMenu
        onChange={e => {
          setSelectedTokenSymbol(e.target.value)
        }}
        className="dropdown"
      >
        {options.map((item, i) => renderOptions(item, i, selectedTokenSymbol, prefix))}
      </SelectMenu>

      <NoHeight>
        <DropControl src={arrow} alt="dropdown-arrow" />
      </NoHeight>
    </>
  )
}

const NoHeight = styled.div`
  height: 0px;
  position: relative;
  top: -45px;
  left: 240px;
`

const DropControl = styled.img`
  width: 22px;
`
