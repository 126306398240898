import React, { useEffect } from 'react'
import styled from 'styled-components'

import { amountFormatter, TRADE_TYPES } from '../utils'
import Button from './Button'

import { useAppContext } from '../context'
import { saintfameGreen } from '../theme'

const ConfirmedFrame = styled.div`
  width: 100%;
  max-width: 780px;
  background: #242424;
  color: #fffafa;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px;
  align-items: center;

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    width: 580px;
    overflow: scroll;
    height: 100vh;
  }
`

// function Controls({ closeCheckout }) {
//   return (
//     <FrameControls>
//       <Close src={close} onClick={() => closeCheckout()} alt="close" />
//     </FrameControls>
//   )
// }

export default function Confirmed({ hash, type, amount, clearLastTransaction, closeCheckout, chosenProductSymbol }) {
  const [state] = useAppContext()

  function link(hash) {
    return `https://etherscan.io/tx/${hash}`
  }

  useEffect(() => {
    if (!state.visible) {
      clearLastTransaction()
    }
  }, [state.visible, clearLastTransaction])

  if (type === TRADE_TYPES.UNLOCK) {
    return (
      <ConfirmedFrame>
        <InfoFrame>
          <Owned>
            <p> Unlocked Token!</p>
          </Owned>
        </InfoFrame>
        <CheckoutPrompt>
          <EtherscanLink href={link(hash)} target="_blank" rel="noopener noreferrer">
            Transaction Details ↗
          </EtherscanLink>
        </CheckoutPrompt>
        <ButtonFrame
          className="button"
          type={'cancel'}
          text="Close"
          style={{}}
          onClick={() => {
            closeCheckout()
          }}
        />
      </ConfirmedFrame>
    )
  } else if (type === TRADE_TYPES.BUY) {
    return (
      <ConfirmedFrame>
        <InfoFrame>
          <Owned>
            <p> {`You got ${amountFormatter(amount, 18, 0)} ${chosenProductSymbol}!`}</p>
          </Owned>
        </InfoFrame>
        <CheckoutPrompt>
          <EtherscanLink href={link(hash)} target="_blank" rel="noopener noreferrer">
            Transaction Details ↗
          </EtherscanLink>
        </CheckoutPrompt>
        <ButtonFrame
          className="button"
          type={'cancel'}
          text="Close"
          style={{}}
          onClick={() => {
            closeCheckout()
          }}
        />
      </ConfirmedFrame>
    )
  } else {
    return (
      <ConfirmedFrame>
        <InfoFrame>
          <Owned>
            <p>You successfully sold your {chosenProductSymbol}!</p>
          </Owned>
        </InfoFrame>
        <CheckoutPrompt>
          <EtherscanLink href={link(hash)} target="_blank" rel="noopener noreferrer">
            Transaction Details ↗
          </EtherscanLink>
        </CheckoutPrompt>
        <ButtonFrame
          className="button"
          type={'cancel'}
          text="Close"
          style={{}}
          onClick={() => {
            closeCheckout()
          }}
        />
      </ConfirmedFrame>
    )
  }
}

const ButtonFrame = styled(Button)`
  width: 280px;
  margin: 16px;
  height: 48px;
`

const InfoFrame = styled.div`
  width: 100%;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 0;
  justify-content: 'center';
  align-items: flex-end;
  padding: 0;
  /* padding: 1rem 0 1rem 0; */
  margin-top: 12px;
  margin-bottom: 8px;
  border-radius: 0px;
  color: #fffafa;
`

const Owned = styled.div`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 12px;
  margin: 0px;
  color: #fffafa;
  white-space: pre-wrap;
`

const CheckoutPrompt = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin: 16px 16px 0 16px !important;
  text-align: left;
  color: #fffafa;
  font-style: italic;
  width: 100%;
`

const EtherscanLink = styled.a`
  text-decoration: none;
  color: ${saintfameGreen};
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  width: 100%;
  margin: 0;
`
