import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'

import Button from './Button'
import SelectToken from './SelectToken'
import IncrementToken from './IncrementToken'
import { useAppContext } from '../context'
import { ERROR_CODES, amountFormatter, TRADE_TYPES, PRODUCT_SYMBOLS } from '../utils'
import { saintfameGreen } from '../theme'
import close from './Gallery/close.svg'
import { injected } from '../connectors'

export function Controls({ closeCheckout }) {
  return (
    <FrameControls>
      <Close src={close} onClick={() => closeCheckout()} alt="close" />
    </FrameControls>
  )
}
const Close = styled.img`
  width: 32px;
  margin-right: -72px;
  height: 32px;
  cursor: pointer;
  padding: 20px;
`

const FrameControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: right;
  background: black;
`

export function useCount() {
  const [state, setState] = useAppContext()

  function increment() {
    setState(state => ({ ...state, count: state.count + 1 }))
  }

  function decrement() {
    if (state.count >= 1) {
      setState(state => ({ ...state, count: state.count - 1 }))
    }
  }

  function setCount(val) {
    let int = val.toInt()
    setState(state => ({ ...state, count: int }))
  }
  return [state.count, increment, decrement, setCount]
}

function getValidationErrorMessage(validationError) {
  if (!validationError) {
    return null
  } else {
    switch (validationError.code) {
      case ERROR_CODES.INVALID_AMOUNT: {
        return 'Invalid Amount'
      }
      case ERROR_CODES.INVALID_TRADE: {
        return 'Invalid Trade'
      }
      case ERROR_CODES.INSUFFICIENT_ALLOWANCE: {
        return 'Set Allowance to Continue'
      }
      case ERROR_CODES.INSUFFICIENT_ETH_GAS: {
        return 'Not Enough ETH to Pay Gas'
      }
      case ERROR_CODES.INSUFFICIENT_SELECTED_TOKEN_BALANCE: {
        return 'Not Enough of Selected Token'
      }
      default: {
        return 'Unknown Error'
      }
    }
  }
}

export default function BuyAndSell({
  selectedTokenSymbol,
  setSelectedTokenSymbol,
  ready,
  unlock,
  validateBuy,
  buy,
  validateSell,
  dollarPrice,
  pending,
  reserveSOCKSToken,
  sell,
  dollarize,
  setCurrentTransaction,
  currentTransactionHash,
  setShowConnect,
  closeCheckout,
  totalSupply,
  chosenProductSymbol
}) {
  const [state] = useAppContext()
  const { account, activate } = useWeb3React()

  const buying = state.tradeType === TRADE_TYPES.BUY
  const selling = !buying

  const [buyValidationState, setBuyValidationState] = useState({}) // { maximumInputValue, inputValue, outputValue }
  const [sellValidationState, setSellValidationState] = useState({}) // { inputValue, outputValue, minimumOutputValue }
  const [validationError, setValidationError] = useState()

  function link(hash) {
    return `https://etherscan.io/tx/${hash}`
  }

  function getText(account, buying, errorMessage, ready, pending, hash) {
    if (account === undefined) {
      return 'Connect Wallet'
    } else if (ready && !errorMessage) {
      if (!buying) {
        if (pending && hash) {
          return 'Waiting for confirmation'
        } else {
          return 'Sell'
        }
      } else {
        if (pending && hash) {
          return 'Waiting for confirmation'
        } else {
          return 'Buy'
        }
      }
    } else {
      return errorMessage ? errorMessage : 'Loading...'
    }
  }

  // buy state validation
  useEffect(() => {
    if (ready && buying) {
      try {
        const { error: validationError, ...validationState } = validateBuy(String(state.count))
        setBuyValidationState(validationState)
        setValidationError(validationError || null)

        return () => {
          setBuyValidationState({})
          setValidationError()
        }
      } catch (error) {
        setBuyValidationState({})
        setValidationError(error)
      }
    }
  }, [ready, buying, validateBuy, state.count])

  // sell state validation
  useEffect(() => {
    if (ready && selling) {
      try {
        const { error: validationError, ...validationState } = validateSell(String(state.count))
        setSellValidationState(validationState)
        setValidationError(validationError || null)

        return () => {
          setSellValidationState({})
          setValidationError()
        }
      } catch (error) {
        setSellValidationState({})
        setValidationError(error)
      }
    }
  }, [ready, selling, validateSell, state.count])

  const shouldRenderUnlock = validationError && validationError.code === ERROR_CODES.INSUFFICIENT_ALLOWANCE

  const errorMessage = getValidationErrorMessage(validationError)

  function renderFormData() {
    let conditionalRender
    if (buying && buyValidationState.inputValue) {
      conditionalRender = (
        <>
          ${ready && amountFormatter(dollarize(buyValidationState.inputValue), 18, 2)}
          {/* ({amountFormatter(buyValidationState.inputValue, 18, 4)} {selectedTokenSymbol}) */}
        </>
      )
    } else if (selling && sellValidationState.outputValue) {
      conditionalRender = (
        <>
          ${ready && amountFormatter(dollarize(sellValidationState.outputValue), 18, 2)}
          {/* ({amountFormatter(sellValidationState.outputValue, 18, 4)} {selectedTokenSymbol}) */}
        </>
      )
    } else {
      conditionalRender = <>...</>
    }

    return <>{conditionalRender}</>
  }

  function TokenVal(selectedTokenSymbol) {
    var baseDecimals = selectedTokenSymbol === 'USDC' ? 6 : 18

    if (buying && buyValidationState.inputValue) {
      return amountFormatter(buyValidationState.inputValue, baseDecimals, 4)
    } else if (selling && sellValidationState.outputValue) {
      return amountFormatter(sellValidationState.outputValue, baseDecimals, 4)
    } else {
      return '0'
    }
  }

  return (
    <>
      {/* <HideMobile> */}
      {/* <Controls closeCheckout={closeCheckout} /> */}
      {/* </HideMobile> */}
      <TopFrame>
        {/* <Controls closeCheckout={closeCheckout} /> */}
        <InfoFrame pending={pending}>
          <Column>
            <Row>
              <span>
                {chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? (
                  <Title>SAINT FAME: Genesis Shirt</Title>
                ) : (
                  <Title>SAINT FAME: $ICK Mask</Title>
                )}
              </span>
              <span>
                <Title>
                  {reserveSOCKSToken && `${amountFormatter(reserveSOCKSToken, 18, 0)} of ${totalSupply} available`}
                </Title>
              </span>
            </Row>
            <Row>
              <span>
                <Title />
              </span>
              <span />
            </Row>
            <Row>
              <span>
                <BigTitle>Quantity:</BigTitle>
              </span>
              <IncrementToken />
            </Row>
          </Column>
        </InfoFrame>

        {pending && currentTransactionHash ? (
          <CheckoutControls buying={buying} style={{ paddingTop: '60px' }}>
            <CheckoutPrompt>Your transaction is pending.</CheckoutPrompt>
            <CheckoutPrompt>
              <EtherscanLink href={link(currentTransactionHash)} target="_blank" rel="noopener noreferrer">
                View on Etherscan.
              </EtherscanLink>
            </CheckoutPrompt>
          </CheckoutControls>
        ) : (
          <CheckoutControls buying={buying}>
            <Column>
              <Row style={{ paddingTop: '60px' }}>
                <span>
                  <CheckoutPrompt>
                    {buying ? 'How do you want to pay?' : 'What token do you want to receive?'}
                  </CheckoutPrompt>
                </span>
                <span>
                  <SelectToken
                    selectedTokenSymbol={selectedTokenSymbol}
                    setSelectedTokenSymbol={setSelectedTokenSymbol}
                    prefix={TokenVal(selectedTokenSymbol)}
                    chosenProductSymbol={chosenProductSymbol}
                  />
                </span>
              </Row>
            </Column>
          </CheckoutControls>
        )}
        {shouldRenderUnlock ? (
          <Column>
            <Row style={{ paddingTop: '60px' }}>
              <ButtonFrame
                text={`Unlock ${
                  buying ? selectedTokenSymbol : chosenProductSymbol === PRODUCT_SYMBOLS.FAME ? 'FAME' : 'SICK'
                }`}
                type={'cta'}
                pending={pending}
                onClick={() => {
                  unlock(buying).then(({ hash }) => {
                    setCurrentTransaction(hash, TRADE_TYPES.UNLOCK, undefined)
                  })
                }}
              />
            </Row>
          </Column>
        ) : (
          <>
            <Column>
              <Row style={{ paddingTop: '30px' }}>
                <span>
                  <BigTitle>Total: {renderFormData()}</BigTitle>
                </span>
                <span>
                  <ButtonFrame
                    style={{ margin: '0px', width: '280px' }}
                    className="button"
                    pending={pending}
                    disabled={validationError !== null || (pending && currentTransactionHash)}
                    text={getText(account, buying, errorMessage, ready, pending, currentTransactionHash)}
                    type={'buysell'}
                    onClick={() => {
                      if (account === undefined) {
                        activate(injected, undefined, true).catch(() => {
                          setShowConnect(true)
                        })
                      } else {
                        ;(buying
                          ? buy(buyValidationState.maximumInputValue, buyValidationState.outputValue)
                          : sell(sellValidationState.inputValue, sellValidationState.minimumOutputValue)
                        ).then(response => {
                          setCurrentTransaction(
                            response.hash,
                            buying ? TRADE_TYPES.BUY : TRADE_TYPES.SELL,
                            buying ? buyValidationState.outputValue : sellValidationState.inputValue
                          )
                        })
                      }
                    }}
                  />
                </span>
              </Row>
              <ShowOnlyMobile>
                <Row style={{ margin: '0px', paddingTop: '50px' }}>
                  <span>
                    <ButtonFrame
                      className="button"
                      type={'cancel'}
                      text="Cancel"
                      style={{ width: '280px' }}
                      onClick={() => {
                        closeCheckout()
                      }}
                    />
                  </span>
                </Row>
              </ShowOnlyMobile>
            </Column>
          </>
        )}
      </TopFrame>
    </>
  )
}

const Title = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #fffafa;
  width: 100%;
  margin: 0;
`

const BigTitle = styled.p`
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #fffafa;
  padding-top: 20px;
`

const ShowOnlyMobile = styled.span`
  display: none;

  @media only screen and (max-width: 580px) {
    display: block;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 580px) {
    /* For mobile phones: */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: default;
`

const TopFrame = styled.div`
  width: 100%;
  max-width: 780px;
  background: #242424;
  color: ${saintfameGreen};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 30px;

  @media only screen and (max-width: 580px) {
    width: 580px;
    overflow: scroll;
    height: 100vh;
  }
`

const InfoFrame = styled.div`
  opacity: ${props => (props.pending ? 0.6 : 1)};
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px;
`

const CheckoutControls = styled.span`
  width: 100%;
  margin: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CheckoutPrompt = styled.p`
  margin-bottom: 0;
  margin-left: 8px;
  text-align: left;
  width: 100%;
  font-family: Tenor Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  color: #fffafa;
`

const ButtonFrame = styled(Button)`
  height: 70px;
`

const EtherscanLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 8px;
`
