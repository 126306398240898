import React from 'react'
import styled from 'styled-components'

const ButtonFrame = styled.button`
  padding: 0;
  text-align: center;
  font-family: 'Tenor Sans', sans-serif;
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: center;
  font-size: 20px;
  line-height: 30px;
  line-height: 1;
  cursor: pointer;
  border-style: solid;
  opacity: ${props => (props.disabled ? 0.95 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  border-width: 4px;
  border-color: ${props => {
    // There is definately a better way of doing all these different colours... but not enough time.
    var color = props.theme.primary

    if (props.type === 'cta') {
      color = props.theme.secondary
    }

    if (props.type === 'cancel') {
      color = '#FFFAFA'
    }

    if (props.disabled) {
      color = 'rgba(104, 104, 104, 1)'
    }

    if (props.type === 'redeem') {
      color = props.theme.secondary
    }

    return color
  }};

  background: ${props => {
    var color = 'black'

    if (props.disabled) {
      color = 'rgba(104, 104, 104, 0.2)'
    }

    if (props.type === 'buysell') {
      color = '#242424'
    }

    if (props.type === 'cancel') {
      color = '#242424'
    }

    if (props.type === 'redeem') {
      color = '#242424'
    }

    return color
  }};
  color: ${props => {
    var color = props.theme.primary

    if (props.type === 'cta') {
      color = props.theme.secondary
    }

    if (props.type === 'cancel') {
      color = '#FFFAFA'
    }

    if (props.disabled) {
      color = 'rgba(104, 104, 104, 1)'
    }

    if (props.type === 'redeem') {
      color = props.theme.secondary
    }

    return color
  }};
  :active {
    outline: none;
    opacity: 0.5;
  }
  :hover {
    background: ${props => {
      var color = 'rgba(36, 255, 0, 0.2)'

      if (props.type === 'cta') {
        color = 'rgba(255, 0, 214, 0.2)'
      }

      if (props.type === 'cancel') {
        color = 'rgba(255, 250, 250, 0.2)'
      }

      if (props.disabled) {
        color = 'rgba(104, 104, 104, 0.2)'
      }

      if (props.type === 'cancel') {
        color = 'rgba(36, 36, 36, 1)'
      }

      if (props.type === 'redeem') {
        color = 'rgba(255, 0, 214, 0.2)'
      }

      return color
    }};
    color: ${props => {
      var color = props.theme.primary

      if (props.type === 'cta') {
        color = props.theme.secondary
      }

      if (props.type === 'cancel') {
        color = '#FFFAFA'
      }

      if (props.disabled) {
        color = '#686868'
      }

      if (props.type === 'redeem') {
        color = 'rgba(255, 0, 214)'
      }

      return color
    }};
  }
`

const CtaText = styled.div`
  width: 100%;
`

export default function Button({ text, onClick = () => {}, preventDefault = true, pending, ...rest }) {
  return (
    <ButtonFrame
      onClick={e => {
        if (preventDefault) {
          e.preventDefault()
        }
        onClick(e)
      }}
      {...rest}
    >
      <CtaText>{text}</CtaText>
    </ButtonFrame>
  )
}
